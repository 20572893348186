import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import Location from "./Location";
import Map from "./Map";
import Footer from "./Footer";

const theme = createTheme({
  palette: {
    primary: {
      light: "#89c148",
      main: "#669d36",
      dark: "#31671c",
    },
    secondary: {
      light: "#c4e0a4",
      main: "#add480",
      dark: "#9bcb64",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Montserrat",
      "Helvetica",
      "Arial",
      "sans-serif",
      "-apple-system",
    ].join(","),
    body1: {
      color: "rgba(0, 0, 0, 0.87)",
     // fontSize: { xs: 16, md: 32 }
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "white",
          '&.Mui-checked': {
            color: "white",
          },
        }
      }
    }
    // Name of the component
    // MuiButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    //       border: 0,
    //       borderRadius: 3,
    //       boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    //       color: "white",
    //       height: 48,
    //       padding: "0 30px",
    //     },
    //   },
    // },
  },
});

function App() {
  let center = {
    lat: 33.6863,
    lng: -117.8263,
  };
  const cl = localStorage.getItem("current_location");
  console.log("center", cl)
  if (cl !== null) {
    center = {
      lat: JSON.parse(cl).lat,
      lng: JSON.parse(cl).lng,
    };
  }
  const [defaultCenter, setDefaultCenter] = useState(center);

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container>
            <Location setDefaultCenter={setDefaultCenter} />
            <Map defaultCenter={defaultCenter} />
          </Container>
          <Footer theme={theme} />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
