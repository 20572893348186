import React from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import utils from "./utils";

export default function Week(props) {
  const handleChange = (e) => {
    var week = props.week.map((m, i) => {
      if (e.target.value === m.id)
        return {...m, checked: e.target.checked};
      else
        return {...m};
    });
    props.setDow(week);
  }
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row onChange={handleChange}>
        
        {utils.WeekArray.map((w) => (
          <FormControlLabel
            key={`f${w.id}`}
            value={w.id}
            control={<Checkbox 
              //  onChange={handleChange(`${w.id}`)}
             />}
            label={
            <Typography 
              variant="h6" style={{ color: 'white', marginLeft: '-8px', }}>{w.name}</Typography>}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
