import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//   customButton: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     border: 0,
//     borderRadius: 3,
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//     color: "white",
//     height: 48,
//     padding: "0 30px",
//   },
//   footer: {
//     padding: theme.spacing(8),
//     backgroundColor: theme.palette.primary.main,
//   },
// }));

export default function Footer(props) {
  return (
    <footer color="primary" >
      <Container maxWidth="lg" color="primary">
        <Typography variant="h6" align="center" gutterBottom>
        </Typography>
        <Button href="https://wherefarmersmarket.com/contact/">Contact US</Button>
        <Button href="https://wherefarmersmarket.com/local-grocery-alternatives/">Local Grocery Alternatives</Button>
    
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
          fontSize="0.6em !important"
        >
          Copyright © 2020-2024 Where Farmers Market All Rights Reserved.
        </Typography>
      </Container>
    </footer>
  );
}
