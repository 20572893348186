import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import MarketCard from "./MarketCard";
import MobileCard from "./MobileCard";

export default function Market(props: MarketProps) {
  const [hoveredid, setIsHovering] = useState("");
  const handleMouseOver = (id) => {
    setIsHovering(id);
    console.log("hover " + id);
  };

  const handleMouseOut = () => {
    setIsHovering("");
  };

  const divStyle = props.matches ? {
    background: `white`,
    width: 530,
    maxWidth: 530,
  } : 
  {
    background: `white`,
    width: 215,
    maxWidth: 215,
  };

  const onLoad = (infoWindow) => {
    //console.log("infoWindow: ", infoWindow);
  };
  return (
    <div>
      {props.markets.map((m, index) => {
        let p = m.properties;
        let pos = {
          lat: m.geometry.coordinates[0],
          lng: m.geometry.coordinates[1],
        };
        //const icon = "https://wherefarmersmarket.com/map/images/flag.jpg";
        //const icon = "https://wherefarmersmarket.com/map/images/flag2.png";
        const icon = "https://wherefarmersmarket.com/map/images/flag3.png";
        return (
          <div key={`d${index}`}>
            <Marker
              icon={icon}
              label={props.name}
              position={pos}
              key={`m${index}`}
              onClick={() => props.setCard(p.storeid)}
              onMouseOver={() => handleMouseOver(p.storeid)}
              onMouseOut={handleMouseOut}
            >
              {p.storeid === props.openedid && props.matches && (
                <InfoWindow onLoad={onLoad} position={pos} key={`ilg${index}`}>
                  <div style={divStyle} key={`dlg${index}`}>
                    <MarketCard
                      id={`crd${p.storeid}`}
                      image={p.image ? p.image : "348s.jpg"}
                      name={p.name}
                      address={p.address}
                      dow={p.dow}
                      hours={p.hours}
                      season={p.season}
                      bathroom={p.bathroom}
                      parking={p.parking}
                      knife={p.knife}
                      phone={p.phone}
                      website={p.website}
                      showCloseButton={true}
                      handleClose={() => props.setCard("")}
                      key={`c${index}`}
                      matches={props.matches}
                      cardWidth={`530px`}
                    />
                  </div>
                </InfoWindow>
              )}
              {p.storeid === props.openedid && !props.matches && (
                <InfoWindow onLoad={onLoad} position={pos} key={`ism${index}`} id={`mbism${index}`}>
                  <div style={divStyle} key={`dsm${index}`}>
                    <MobileCard
                      id={`crdsm${p.stpreid}`}
                      image={p.image ? p.image : "348s.jpg"}
                      name={p.name}
                      address={p.address}
                      dow={p.dow}
                      hours={p.hours}
                      phone={p.phone}
                      website={p.website}
                      showCloseButton={true}
                      handleClose={() => props.setCard("")}
                      key={`c${index}`}
                      matches={props.matches}
                    />
                  </div>
                </InfoWindow>
              )}
              {p.storeid === hoveredid && props.matches && (
                <InfoWindow onLoad={onLoad} position={pos} key={`i2${index}`}>
                  <div>{p.name}</div>
                </InfoWindow>
              )}
            </Marker>
          </div>
        );
      })}
    </div>
  );
}
