import React from "react";
import Box from "@mui/material/Box";
import Results from "./Results";

export default function MobileResults(props) {
  return (
    <Box sx={{ display: "flex", width: 430, height: 300, overflow: "scroll" }}>
      <Results
        matches={props.matches}
        nearby={props.nearby}
        cardWidth={`370px`}
        openedid={props.openedid}   
        setCard={props.setCard}     
      ></Results>
    </Box>
  );
}
