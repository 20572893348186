import { React } from "react";
import MarketCard from "./MarketCard";

export default function Results(props) {
  let cardWidth = props.cardWidth ? props.cardWidth : `530px`;
  return (
    <div>
      {props.nearby.map((nb, index) => {
        const p = nb.properties;
        let tabIndex = props.openedid === p.storeid ? 0 : 10 + index;
        return (
          nb.order > -1 &&
          nb.order < 6 && (
            <div key={`rtxt${p.storeid}`} tabIndex={tabIndex}>
              <MarketCard
                id={`rst${p.storeid}`}
                name={p.name}
                address={p.address}
                dow={p.dow}
                hours={p.hours}
                season={p.season}
                bathroom={p.bathroom}
                parking={p.parking}
                knife={p.knife}
                phone={p.phone}
                website={p.website}
                distance={nb.distance}
                handleClose={() => props.setCard("")}
                key={`c${index}`}
                cardWidth={cardWidth}
                matches={props.matches}
                noBorder={true}
                openedid={props.openedid}
                setCard={props.setCard}     
              />
            </div>
          )
        );
      })}
    </div>
  );
}
