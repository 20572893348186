import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function MobileCard(props: MarketCardProps) {
  let divStyle = {
    padding: `2px`,
    border: `none`,    
  };
  const direction =
    "https://www.google.com/maps/dir/?api=1&origin=&destination=" +
    encodeURI(props.address);
  const addArray = props.address.split(",");
  const address1 = addArray[0];
  const address2 = props.address.replace(address1 + ",", " ");

  return (
    <Card
      sx={{
        maxWidth: 215,
        position: "relative",
        zIndex: 1001,
        width: 215,
      }}
      key={props.id}
    >
      <CardContent style={divStyle}>
        <CardHeader
          sx={{
            padding: "3px",
          }}
          title={props.name}
          titleTypographyProps={{ fontSize: `1em` }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <LocationOnIcon fontSize="small"></LocationOnIcon> <a style={{ textDecoration: 'none' }} href={direction} target="_blank" rel="noreferrer">{address1}
          <br></br>
          {address2}</a>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <b>OPEN: </b>
          {props.dow}
          <br></br>
          <b>HOURS: </b>
          {props.hours}
        </Typography>
      </CardContent>
    </Card>
  );
}
