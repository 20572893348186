import React, { useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { Autocomplete } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import Results from "./Results";
import Week from "./Week";
const drawerWidth = 540;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  // border: "2px solid #FF0000",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));
export default function Panel(props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [obj, setAutocomplete] = React.useState(null);
  const [objTop, setAutocompleteTop] = React.useState(null);
  const [search, setSearchInput] = useState({
    top: "",
    side: "",
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSearchInput = (e) => {
    if (e === undefined) {
      setSearchInput({
        top: "",
        side: "",
      });
    } else {
      if (e.target.name === "top")
        setSearchInput({top: e.target.value});
      else
        setSearchInput({side: e.target.value});
    }
  };
  const onPlacehanged = (top) => {
    const place = top === "top" ? objTop : obj;
    console.log("place", place);

    if (place !== null) {
      //console.log("Panel result;", place.getPlace());
      const info = place.getPlace();
      if (info !== undefined) {
        setSearchInput({
          top: info.formatted_address,
          side: info.formatted_address,
        });

        const newCenter = {
          lat: info.geometry.location.lat(),
          lng: info.geometry.location.lng(),
        };
        props.handleLocationChange(newCenter);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
      <Toolbar sx={{ display: { xs: 'block', sm: 'none', md: 'none' }}}>
        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: "pacifico",
            }}
          >
            Where Farmers Market?
          </Typography>
        </Toolbar>
        <Toolbar>
          {props.matches ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            ""
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              fontFamily: "pacifico",
            }}
          >
            Where Farmers Market?
          </Typography>
          {props.matches ? (<Week sx={{ display: { xs: 'none', lg: 'block' } }} setDow={props.setDow} week={props.week}/>) : ("")}
          
          <Search
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Autocomplete
              onLoad={setAutocompleteTop}
              onPlaceChanged={() => onPlacehanged("top")}
            >
              <StyledInputBase
                placeholder="Find the nearest market"
                inputProps={{ "aria-label": "search" }}
                value={search.top}
                onChange={handleSearchInput}
                name="top"
              />
            </Autocomplete>
            <IconButton
              type="delete address"
              color="secondary"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => handleSearchInput()}
            >
              <CloseIcon />
            </IconButton>
          </Search>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Search
            sx={{
              display: "flex",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Autocomplete
              onLoad={setAutocomplete}
              onPlaceChanged={onPlacehanged}
            >
              <StyledInputBase
                placeholder="Find the nearest market"
                inputProps={{ "aria-label": "search" }}
                value={search.side}
                onChange={handleSearchInput}
                name="side"
              />
            </Autocomplete>
            <IconButton
              type="delete address"
              color="secondary"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => handleSearchInput()}
            >
              <CloseIcon />
            </IconButton>
          </Search>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Results nearby={props.nearby} cardWidth={`530px`}></Results>
      </Drawer>
      <Main open={open}></Main>
    </Box>
  );
}
