const utils = {};

export function GetNearByFromCenter(center, week, markets) {
  const latc = center.lat;
  const lngc = center.lng;

  var weekFiltered = week.filter((w) => {
    return w.checked;
  });
  console.log(week, weekFiltered);
  let filteredMarkets = markets;
  if (weekFiltered.length > 0) {
    filteredMarkets = markets.filter((m) => {
      let found = false;
      weekFiltered.forEach((w) => {
        if (m.properties.dow.startsWith(w.id)) {
          found = true;
        }
      });
      return found;
    });
  }
  var distances = filteredMarkets.map((m, i) => {
    const [lat, lng] = m.geometry.coordinates;
    let distance = getDistance(latc, lngc, lat, lng, "N");
    //let distance = calcCrow(latc, lngc, lat, lng);
    return { ...m, distance: distance };
  });

  distances.sort((a, b) => {
    return a.distance - b.distance;
  });
  
  console.log("markets", markets.length);
  console.log("filteredMarkets", filteredMarkets.length);
  console.log("distances", distances.length);

  return distances.map((m, i) => {
    return { ...m, order: i };
  });
}
export function GetNearByAddress(nearby) {
  let destinations = [];
  nearby.forEach((m, i) => {
    destinations.push(m.address);
  });
  return destinations;
}

function getDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export function ParseResponse(response, nearby) {
  response.destinationAddresses.forEach((a, i) => {
    nearby.forEach((n) => {
      if (a.startsWith(n.address)) {
        n.order = i;
      }
    });
  });
  nearby.forEach((n) => {
    if (n.order !== undefined) {
      n.distance = response.rows[0].elements[n.order].distance.text;
      n.duration = response.rows[0].elements[n.order].duration.text;
    }
  });
  //console.log("nearby",nearby)
  return nearby;
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
// function calcCrow(lat1, lon1, lat2, lon2)
// {
//   var R = 6371; // km
//   var dLat = toRad(lat2-lat1);
//   var dLon = toRad(lon2-lon1);
//   var dlat1 = toRad(lat1);
//   var dlat2 = toRad(lat2);

//   var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//     Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(dlat1) * Math.cos(dlat2);
//   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
//   var d = R * c;
//   return d;
// }

// Converts numeric degrees to radians
// function toRad(Value)
// {
//     return Value * Math.PI / 180;
// }

utils.WeekArray = [
  { id: "Sunday", name: "Sun", checked: false },
  { id: "Monday", name: "Mon", checked: false },
  { id: "Tuesday", name: "Tue", checked: false },
  { id: "Wednesday", name: "Wed", checked: false },
  { id: "Thursday", name: "Thu", checked: false },
  { id: "Friday", name: "Fri", checked: false },
  { id: "Saturday", name: "Sat", checked: false },
];

// export function FilterWeeks(obj) {
//   return Object.keys(obj).filter(key => {
//     return obj[key];
//   });
// }

// export function FilterMarkersByWeek(markers, weeks) {
//   let newMarkers = [];
//   markers.map(m => {
//     let selected = false;
//     weeks.map(ws => {
//       return (selected = selected ? selected : m.week[ws]);
//     });
//     return selected && newMarkers.push(m);
//   });
//   return newMarkers;
// }

// export function filterBySearchTerm(markers, term) {
//   let filteredMarkers = [];

//   markers.map((marker, i) => {
//     if (
//       marker.name.toLocaleLowerCase().indexOf(term) > -1 ||
//       marker.city.toLocaleLowerCase().indexOf(term) > -1
//     ) {
//       filteredMarkers.push(marker);
//     }
//     return marker;
//   });
//   return filteredMarkers;
// }

export default utils;
