import React, { Component } from "react";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setCurrentPosition = (location) => {
    const center = {
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    };
    this.props.setDefaultCenter(center);
    localStorage.setItem('current_location', JSON.stringify(center));
  };

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(this.setCurrentPosition);
  }

  render() {
    return <div></div>;
  }
}

export default Location;
