import React from "react";
import Link from '@mui/material/Link';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, Button, ListItem } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function MarketCard(props: MarketCardProps) {
  console.log("props.matches", props.matches)
  let divStyle = props.noBorder
    ? {
        //mobile bottom, left side panel
        border: `none`,
        borderBottom: `solid #c4e0a4 1px`,
      }
    : {
        paddingBottom: `1px`,
        paddingTop: `5px`,
        border: `solid #c4e0a4 1px`,
        borderRadius: `10px`,
      };
  const direction =
    "https://www.google.com/maps/dir/?api=1&origin=&destination=" +
    encodeURI(props.address);
  const bgColor = props.openedid === props.id ? `secondary.light` : `white`;
  const handleShowOnMap = (id) => {
    props.setCard(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <Card
      sx={{
        maxWidth: 550,
        position: "relative",
        zIndex: 1001,
        width: props.cardWidth,
        backgroundColor: bgColor,
      }}
      key={props.id}
      id={`mc-${props.id}`}
      >
      {props.image ? (
        <CardMedia
          sx={{
            height: 80,
          }}
          image={`./images/${props.image}`}
          title={props.name}
        />
      ) : (
        ""
      )}
      <CardContent style={divStyle}>
        <CardHeader
          sx={{
            paddingLeft: "0px",
            marginLeft: "-5px",
          }}
          avatar={
            <Avatar
              sx={{ bgcolor: "primary.dark", paddingRight: "0px" }}
              aria-label={props.dow}
            >
              {props.dow.substring(0, 3)}
            </Avatar>
          }
          title={props.name}
          titleTypographyProps={{ fontSize: { xs: `0.9em`, md: `1.2em` } }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <LocationOnIcon fontSize="small"></LocationOnIcon> <Link href={direction} target="_blank" underline="none">{props.address}</Link>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <b>OPEN: </b>
          {props.dow} {props.hours} {props.season ? `(${props.season})` : ``}
        </Typography>
{props.parking ? (
            <Typography variant="body2" color="textSecondary">
            <b>Parking: </b>
            {props.parking}
          </Typography>
          ) : (
            ""
          )}
          {props.knife ? (
            <Typography variant="body2" color="textSecondary">
            <b>Knife Sharpening: </b>
            {props.knife}
          </Typography>
          ) : (
            ""
          )}
        {props.bathroom ? (
            <Typography variant="body2" color="textSecondary">
            <b>Bathroom: </b>
            {props.bathroom}
          </Typography>
          ) : (
            ""
          )}
        <Grid container justifyContent={"flex-start"}>
          {props.distance ? (
            <ListItem size="small">
              {props.distance.toFixed(1)} Miles Away
            </ListItem>
          ) : (
            ""
          )}
        </Grid>
        <Grid container justifyContent={"flex-end"}>
          {props.website ? (
            <Button size="small" href={props.website} target="_blank">
              More Info
            </Button>
          ) : (
            ""
          )}
          <Button size="small" href={direction} target="_blank">
            Direction
          </Button>
          {props.showCloseButton ? (
            <Button size="small" onClick={props.handleClose}>
              Close
            </Button>
          ) : (
            ""
          )}
          {props.matches === false ? (
            <Button size="small" onClick={() => handleShowOnMap(props.id)}>
              Show on Map
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
