const Markets = [
  {
    geometry: {
      type: "Point",
      coordinates: [33.6317417, -117.833713],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "8am - 12pm",
      description: "",
      name: "Irvine Farmer's Market",
      address: "5001 Newport Coast Dr, Irvine, CA 92603",
      phone: "",
      dow: "Saturdays",
      image: "348s.jpg",
      storeid: "01",
      parking: "Free parking is available at Mariners Church Parking",
      bathroom: "Mariners Church bathroom"
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.69503, -117.92604],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 2pm",
      description: "",
      name: "Soco Farmers' Market",
      address: "3315 Hyland Avenue Costa Mesa, CA 92626",
      phone: "",
      dow: "Saturdays",
      image: "",
      website: "http://www.farmermark.com/",
      storeid: "02",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.59964, -117.87179],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 1pm",
      description: "",
      name: "Corona Del Mar Farmers Market",
      address: "3201 CA-1, Corona Del Mar, CA 92625",
      phone: "(760)453-9837",
      email: "delmarfarmersmarket.vendors@gmail.com",
      dow: "Saturdays",
      image: "",
      website: "https://coronadelmarfarmersmarket.com/",
      storeid: "03",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.18989, -117.25475],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 12pm",
      description: "",
      name: "Vista Farmer's Market",
      address: "325 S Melrose Dr, Vista, CA 92081",
      website: "http://vistafarmersmarket.com",
      phone: "(760) 945-7425",
      email: " sherri@vistafarmersmarket.com",
      dow: "Saturdays",
      image: "",
      storeid: "04",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.95536, -117.26402],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "1pm - 4pm",
      description: "",
      name: "Del Mar Certified Farmers Market",
      address: "1050 Camino Del Mar, Del Mar, CA 92014, USA",
      website: "",
      phone: "(760)453-9837",
      email: "delmarfarmersmarket.vendors@gmail.com",
      dow: "Saturdays",
      image: "",
      storeid: "05",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.72319, -117.16815],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "8am - 2pm",
      description: "",
      name: "San Diego â€“ Little Italy Mercato Certified Farmers Market",
      address: "600 W Date St, San Diego, CA 92101",
      website: "https://www.littleitalysd.com/events/mercato",
      phone: "(619) 233-3901",
      email: "vendor@sandiegomarkets.com",
      dow: "Saturdays",
      image: "",
      storeid: "06",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.74768, -117.10057],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 1pm",
      description: "",
      name: "City Heights Farmers' Market",
      address: "600 W Date St, San Diego, CA 92101",
      website: "http://sdfarmbureau.org/index.php",
      phone: "(760) 504-4363",
      email: "market@sdfarmbureau.org",
      dow: "Saturdays",
      image: "",
      storeid: "07",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.38215, -117.25033],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 1:30pm",
      description: "",
      name: "Fallbrook Main Ave Farmers Market",
      address: "111 S Main Ave, Fallbrook, CA 92028",
      website:
        "https://www.fallbrookchamberofcommerce.org/events-v2/fallbrook-market.html",
      phone: "(760) 728-5845",
      email: "info@fallbrookfarmersmarket.org",
      dow: "Saturdays",
      image: "",
      storeid: "08",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.75073, -117.14881],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "8am - 2pm",
      description: "",
      name: "Hillcrest Farmers Market",
      address: "3960 Normal St #3960, San Diego, CA 92103",
      website: "http://Hillcrestfarmersmarket.com",
      phone: "(760) 638-0368",
      email: "hillcrestfarmersmarket@hillcrestbia.org",
      dow: "Sundays",
      image: "",
      storeid: "09",
      bathroom: "portable bathrooms",
      parking: "at DMV near the market or street parking"
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.83852, -117.27118],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9am - 1pm",
      description: "",
      name: "La Jolla Open Aire Farmers Market",
      address: "7335 Girard Ave, La Jolla, CA 92037",
      website: "http://www.lajollamarket.com",
      phone: "(858) 454-1699",
      email: "juliemacd@me.com",
      dow: "Sundays",
      image: "",
      storeid: "10",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.06149, -117.29722],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "10am - 2pm",
      description: "",
      name: "Leucadia Farmers Market",
      address: "185 Union St, Encinitas, CA 92024",
      website: "http://www.leucadiafarmersmarket.com/",
      phone: "(858)-272-7054",
      email: "RonLachance@gsws.com",
      dow: "Sundays",
      image: "",
      storeid: "11",
      bathroom: "school bathrooms",
      parking: "school parking or street parking"
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.06748, -117.06701],
    },
    type: "Feature",
    properties: {
      category: "market3",
      closed: "yes",
      hours: "10:30am - 3:30pm",
      description: "",
      name: "North San Diego Certified Farmers Market",
      address: "12655 Sunset Drive, at the Sikes Adobe Historic Farmstead, Escondido, CA 92025",
      website: "http://www.northsdfarmersmarket.com/",
      phone: "(858) 735-5311",
      email: "info@NSDCFM.com",
      dow: "Sundays",
      image: "",
      storeid: "12",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.99056, -117.19635],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9:30am - 2pm",
      description: "",
      name: "Rancho Santa Fe Farmers Market",
      address: "16079 San Dieguito Rd Suite A3, Rancho Santa Fe, CA 92091",
      website: "http://ranchosantafefarmersmarket.com/",
      phone: "(619) 743-4263",
      email: "Diane@RanchoSantaFeFarmersMarket.com",
      dow: "Sundays",
      image: "",
      storeid: "13",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.10919, -116.67372],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "10am - 6pm",
      description: "",
      name: "Santa Ysabel Farmers Market",
      address: "21851-21887 Washington St, Santa Ysabel, CA 92070",
      website: "",
      phone: "+17607829202",
      email: "info@santaaysabelfarmersmarket.org",
      dow: "Sundays",
      image: "",
      storeid: "14",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.9872, -117.26899],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "12pm - 4pm",
      description: "",
      name: "Solana Beach Farmers Market",
      address: "444 S Cedros Ave, Solana Beach, CA 92075",
      website: "",
      phone: "(858) 755-0444",
      email: "cedrosdesigndistrict.net",
      dow: "Sundays",
      image: "",
      storeid: "15",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.69865, -117.17039],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "2:30pm - 6pm",
      description: "",
      name: "Coronado Certified Farmers' Market",
      address: "1st St & B Ave, Coronado, CA 92118",
      website: "",
      phone: "(760) 741-3763",
      email: "pbfarmmarket@aol.com",
      dow: "Tuesdays",
      image: "",
      storeid: "16",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.12361, -117.0792],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "2:30pm - sunset",
      description: "",
      name: "Escondido Farmers Market",
      address: "262 E Grand Ave, Escondido, CA 92025",
      website: "http://escondidoarts.org/farmers-market/",
      phone: "(760) 480-4101",
      email: "EscondidoFarmersMarket@yahoo.com",
      dow: "Tuesdays",
      image: "",
      storeid: "17",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.91236, -117.13979],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3pm - 6pm (closed in January)",
      description: "",
      name: "Mira Mesa Farmer's Market",
      address: "10510 Marauder Way, San Diego, CA 92126",
      website: "",
      phone: "(858) 272-7054",
      email: "ronlachance@gsws.com",
      dow: "Tuesdays",
      image: "",
      storeid: "18",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.79626, -117.25342],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "2pm - 6pm",
      description: "",
      name: "Pacific Beach Tuesday Farmers' Market",
      address: "901 Hornblend St, San Diego, CA 92109",
      website: "http://www.pacificbeachmarket.com/",
      phone: "(208) 922-8900",
      email: "info@pacificbeachmarket.com",
      dow: "Tuesdays",
      image: "",
      storeid: "19",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.13499, -117.15946],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3-6PM Winter/Fall, 3-7PM Spring/Summer",
      description: "",
      name: "San Marcos Farmers Market",
      address: "251 N City Dr, San Marcos, CA 92078",
      website: "http://www.sanmarcosfarmersmarket.org/",
      phone: "(760) 744-1270",
      email: "melanie@sanmarcoschamber.com",
      dow: "Tuesdays",
      image: "",
      storeid: "20",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.16065, -117.3495],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3 pm-7 pm (Winter Hours 3 – 6 pm)",
      description: "",
      name: "State Street Farmers Market",
      address: "2907 State St, Carlsbad, CA 92008",
      website: "",
      phone: "(858)-272-7054",
      email: "RonLachance@gsws.com",
      dow: "Wednesdays",
      image: "",
      storeid: "21",
      "not checked": "yes",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.72319, -117.16815],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9:30am - 1:30pm",
      description: "",
      name: "Little Italy Mercato Farmers' Market",
      address: "600 W Date St, San Diego, CA 92101",
      website: "https://www.littleitalysd.com/events/mercato",
      phone: "(619) 233-3901",
      email: "vendor@sandiegomarkets.com",
      dow: "Wednesdays",
      image: "",
      storeid: "22",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.74609, -117.24933],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "4 pm – 7 pm (October – March), 4 pm – 8 pm (April – September)",
      description: "",
      name: "Ocean Beach Farmers Market",
      address: "4900 Newport Ave, San Diego, CA 92107",
      website: "http://www.oceanbeachsandiego.com",
      phone: "(619) 279-0032",
      email: "cmmucrops@cox.net",
      dow: "Wednesdays",
      image: "",
      storeid: "23",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.85304, -116.99974],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3:00 -7:00 pm, Winter Hours 3:00 -6:00 pm (Nov-Feb)",
      description: "",
      name: "Santee Farmers Market",
      address: "9280 Mast Blvd, Santee, CA 92071",
      website: "http://santeefarmersmarket.com/",
      phone: "(619)-449-8427",
      email: "santeecertifiedfarmersmarket@gmail.com",
      dow: "Wednesdays",
      image: "",
      storeid: "24",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.66138, -117.03377],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3 pm-7 pm (Winter Hours 3 – 6 pm)",
      description: "",
      name: "South Bay Farmer's Market",
      address: "4471 Bonita Rd, Bonita, CA 91902",
      website: "https://briansfarmersmarkets.com/",
      phone: "(619) 550-7180",
      email: "BFMCSA@gmail.com",
      dow: "Wednesdays",
      image: "",
      storeid: "25",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.78543, -117.16973],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3 pm – 7 pm (March – August), 2 pm – 6 pm (September – February)",
      description: "",
      closed: "yes",
      name: "Linda Vista Certified Farmers' Market",
      address: "6939 Linda Vista Rd, San Diego, CA 92111",
      website: "",
      phone: "(760) 504-4363",
      email: "market@sdfarmbureau.org",
      dow: "Thursdays",
      image: "",
      storeid: "26",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.75079, -117.12912],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3 to 6:30 pm",
      description: "",
      name: "North Park Thursday Market",
      address: "3000 North Park Way, San Diego, CA 92104",
      website: "https://www.northparkfarmersmarket.com/",
      phone: "(619) 289-8909",
      email: "market@northparkmainstreet.com",
      dow: "Thursdays",
      image: "",
      storeid: "27",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.19619, -117.38071],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "9:00 am - 1:00 pm",
      description: "",
      name: "Oceanside Morning Farmers Market",
      address: "401 Pier View Way, Oceanside, CA 92054",
      website: "https://www.mainstreetoceanside.com",
      phone: "(760) 791-3241",
      email: "outbackfarm@sbcglobal.net",
      dow: "Thursdays",
      image: "",
      storeid: "28",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.19619, -117.38071],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "5pm - 9pm",
      description: "",
      name: "Oceanside Sunset Market",
      address: "401 Pier View Way, Oceanside, CA 92054",
      website: "https://www.mainstreetoceanside.com",
      phone: "(760) 791-3241",
      email: "outbackfarm@sbcglobal.net",
      dow: "Thursdays",
      image: "",
      storeid: "29",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [32.9034, -117.11062],
    },
    type: "Feature",
    properties: {
      category: "market3",
      hours: "3:00 pm – 7:00 pm",
      description: "",
      name: "Scripps Ranch Farmers Market",
      address: "10045 Carroll Canyon Rd, San Diego, CA 92131",
      website: "https://www.scrippsranchfarmersmarket.com/",
      phone: "(858) 603-3178",
      email: "",
      dow: "Thursdays",
      image: "",
      storeid: "30",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.83404, -117.91548],
    },
    type: "Feature",
    properties: {
      storeid: "31",
      name: "Downtown Anaheim Farmers Market",
      address: "201 W Center Street Promenade, Anaheim, CA 92805",
      dow: "Thursdays",
      hours: "11:00 am – 3:00 pm",
      website: "https://downtownanaheim.com/",
      phone: "+17149563586",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.85201, -117.84381],
    },
    type: "Feature",
    properties: {
      storeid: "32",
      name: "Anaheim Kaiser Permanente Farmers Market",
      address: "3440 E La Palma Ave, Anaheim, CA 92806",
      dow: "Fridays",
      hours: "9:00 am – 2:00 pm",
      website:
        "https://healthy.kaiserpermanente.org/health-wellness/farmers-markets",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.84677, -117.9927],
    },
    type: "Feature",
    properties: {
      storeid: "33",
      name: "Buena Park Farmers Market",
      address: "8000-8092 La Palma Ave, Buena Park, CA 90620",
      dow: "Saturdays",
      hours: "9:00 am – 2:00 pm",
      website:
        "https://www.visitbuenapark.com/business/buena-park-farmers-market",
      phone: "(562) 449-9288",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.66853, -117.90469],
    },
    type: "Feature",
    properties: {
      storeid: "34",
      name: "Costa Mesa Certified Farmers' Market",
      address: "88 Fair Dr, Costa Mesa, CA 92626",
      dow: "Thursdays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.ocfarmbureau.org/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.80502, -118.05358],
    },
    type: "Feature",
    properties: {
      storeid: "35",
      name: "Cypress Certified Farmers Market",
      address: "4505 Katella Ave, Los Alamitos, CA 90720",
      dow: "Saturdays",
      hours: "9:00 am – 2:00 pm",
      website:
        "https://www.cypressca.org/activities/community-events/certified-farmers-market",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.46882, -117.70068],
    },
    type: "Feature",
    properties: {
      storeid: "36",
      name: "Dana Point Certified Farmers Market",
      address: "34111 La Plaza, Dana Point, CA 92629",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://danapointchamber.com/farmers-market/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.87709, -117.92268],
    },
    type: "Feature",
    properties: {
      storeid: "37",
      name: "Fullerton Farmer's Market",
      address: "121 E Wilshire Ave, Fullerton, CA 92832",
      dow: "Thursdays",
      hours: "4:30 pm – 8:30 pm (April-August)",
      website: "https://www.cityoffullerton.com/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.87248, -117.93103],
    },
    type: "Feature",
    properties: {
      storeid: "38",
      name: "Fullerton Certified Farmers",
      address: "353 W Commonwealth Ave, Fullerton, CA 92832",
      dow: "Wednesdays",
      hours: "8:30 am – 12:30 pm",
      website: "https://www.cityoffullerton.com/",
      phone: "+17148715304",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.77453, -117.94042],
    },
    type: "Feature",
    properties: {
      storeid: "39",
      name: "Garden Grove Farmers Market",
      address: "12976 Main St, Garden Grove, CA 92840",
      dow: "Sundays",
      hours: "10:00 am – 2:00 pm",
      website: "https://www.cdfa.ca.gov/is/i_&_c/cfm.html",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.65902, -117.99906],
    },
    type: "Feature",
    properties: {
      storeid: "40",
      name: "Surf City Nights Street Fair and Certified Farmers' Market",
      address: "315 3rd St suite e, Huntington Beach, CA 92648",
      dow: "Tuesdays",
      hours: "5:00 pm – 9:00 pm",
      website:
        "https://www.surfcityusa.com/things-to-do/attractions/surf-city-nights/",
      phone: "+17145368300",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.67717, -117.85209],
    },
    type: "Feature",
    properties: {
      storeid: "41",
      name: "Irvine Towers Certified Farmers' Market",
      address: "18400 Von Karman Ave, Irvine, CA 92612",
      dow: "Wednesdays",
      hours: "10:00 am – 2:00 pm",
      website: "https://www.enrichedfarms.com/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.65836, -117.77485],
    },
    type: "Feature",
    properties: {
      storeid: "42",
      name: "Irvine Kaiser Permanente Farmers' Market",
      address: "6640 Alton Pkwy, Irvine, CA 92618",
      dow: "Wednesdays",
      hours: "9:00 am – 1:00 pm",
      website:
        "https://healthy.kaiserpermanente.org/southern-california/health-wellness/farmers-markets/locations#enableScroll",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.67641, -117.74382],
    },
    type: "Feature",
    properties: {
      storeid: "43",
      name: "Orange County Great Park Farmers Market",
      address: "6950 Marine Way, Irvine, CA 92618",
      dow: "Sundays",
      hours: "10:00 am – 2:00 pm",
      website: "https://www.cityofirvine.org/great-park/farmers-market",
      phone: "+17147333167",
      category: "market3",
      description: "",
      email: "",
      image: "",
      parking: "The market is located in Lot No. 2, and there is plenty of parking available.",
      bathroom: "Great Park bathrooms"
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.55186, -117.63617],
    },
    type: "Feature",
    properties: {
      storeid: "44",
      name: "Ladera Ranch Farmers Market",
      address: "28275 Avendale Blvd, Ladera Ranch, CA 92694",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://laderaranchochamber.org/Ladera-Ranch-Farmers-Market",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.5475, -117.78138],
    },
    type: "Feature",
    properties: {
      storeid: "45",
      name: "Laguna Beach Saturday Farmers Market",
      address: "521 Forest Ave, Laguna Beach, CA 92651",
      dow: "Saturdays",
      hours: "8:00 am – 12:00 pm, 8:00am - 11:00 am (July and August)",
      website: "http://lagunabeachfarmersmarket.com/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.61253, -117.70892],
    },
    type: "Feature",
    properties: {
      storeid: "46",
      name: "Laguna Hills Certified Farmers Market",
      address: "23541 Calle De La Louisa, Laguna Hills, CA 92653",
      dow: "Fridays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.ocfarmbureau.org/",
      phone: "+19493019180",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.56896, -117.70902],
    },
    type: "Feature",
    properties: {
      storeid: "47",
      name: "Laguna Niguel Farmers' Market",
      address: "27241 La Paz Rd, Laguna Niguel, CA 92677",
      dow: "Sundays",
      hours: "8:00 am – 12:00 pm",
      website: "http://lagunaniguelfarmersmarket.com/",
      phone: "+19493019180",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.60825, -117.92837],
    },
    type: "Feature",
    properties: {
      storeid: "48",
      name: "Newport Beach Farmers' Market",
      address: "104 Mc Fadden Pl, Newport Beach, CA 92663",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "http://www.farmermark.com/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.79189, -117.85598],
    },
    type: "Feature",
    properties: {
      storeid: "50",
      name: "Orange Home Grown Farmer's & Artisans Market",
      address: "303 W Palm Ave, Orange, CA 92866",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://orangehomegrown.org/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.79656, -117.75412],
    },
    type: "Feature",
    properties: {
      storeid: "51",
      name: "Irvine/Tustin farmer’s market",
      address: "1 Irvine Park Rd, Orange, CA 92869",
      dow: "Tuesdays",
      hours: "9:00 am – 1:00 pm",
      website:
        "https://www.orangecoast.com/guide/shopping/irvinetustin-farmers-market-tuesdays-9-a-m-to-1-p-m/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.89841, -117.86974],
    },
    type: "Feature",
    properties: {
      storeid: "52",
      name: "Placentia Farmers Market",
      address: "100 W Santa Fe Ave, Placentia, CA 92870",
      dow: "Tuesdays",
      hours: "4:00 pm – 8:00 pm",
      website: "http://www.placentia.org/placentiafresh",
      phone: "+17149938117",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.42569, -117.61703],
    },
    type: "Feature",
    properties: {
      storeid: "53",
      name: "San Clemente Farmers Market",
      address: "249 Avenida Del Mar, San Clemente, CA 92672",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.cdfa.ca.gov/is/i_&_c/cfm.html",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.53143, -117.67081],
    },
    type: "Feature",
    properties: {
      storeid: "54",
      name: "Farmakis Farms",
      address: "29932 Camino Capistrano, San Juan Capistrano, CA 92675",
      dow: "Fridays",
      hours: "9:00 am – 1:00 pm",
      website: "http://farmakisfarms.com/",
      phone: "+19493641270",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.76152, -118.07873],
    },
    type: "Feature",
    properties: {
      storeid: "55",
      name: "Seal Beach Farmers Market",
      address: "13960 Seal Beach Blvd, Seal Beach, CA 90740",
      dow: "Tuesdays",
      hours: "9:00 am – 1:00 pm",
      website: "",
      phone: "+17148620055",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.74812, -117.86617],
    },
    type: "Feature",
    properties: {
      storeid: "56",
      name: "DTSA Farmers' Market",
      address: "3rd and Bush Santa Ana, CA 92701",
      dow: "Tuesdays",
      hours: "10:00 am – 2:00 pm",
      website: "",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.74113, -117.82417],
    },
    type: "Feature",
    properties: {
      storeid: "57",
      name: "Old Town Tustin OCFB Certified Farmers' Market",
      address: "474 El Camino Real, Tustin, CA 92780",
      dow: "Wednesdays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.ocfarmbureau.org/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.47559, -117.26157],
    },
    type: "Feature",
    properties: {
      storeid: "58",
      name: "High Desert Farmer’s Market",
      address: "18422 Bear Valley Rd, Victorville, CA 92395",
      dow: "Thursdays",
      hours: "8:00 am – 12:00 pm",
      website: "https://highdesertfarmersmarket.com/",
      phone: "+17602473769",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.00635, -117.72943],
    },
    type: "Feature",
    properties: {
      storeid: "59",
      name: "Chino Hills Farmers Market",
      address: "13920 City Center Dr, Chino Hills, CA 91709",
      dow: "Wednesdays",
      hours: "3:30 pm – 7:30 pm",
      website: "https://heritagefarmersmarket.org/chino-hills",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.09586, -117.71645],
    },
    type: "Feature",
    properties: {
      storeid: "60",
      name: "Claremont Farmers & Artisans Market",
      address: "Harvard Ave N, Claremont, CA 91711",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.claremontforum.org/",
      phone: "+17143453087",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.8761, -117.56682],
    },
    type: "Feature",
    properties: {
      storeid: "61",
      name: "Corona Farmers Market",
      address: "N Main St, Corona, CA 92880",
      dow: "Saturdays",
      hours: "8:30 am – 12:30 pm",
      website: "https://www.myfarmersmarkets.com/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.49601, -117.15084],
    },
    type: "Feature",
    properties: {
      storeid: "62",
      name: "Temecula Farmer’s Market",
      address: "Old Town Front St &, 6th St, Temecula, CA 92590",
      dow: "Saturdays",
      hours: "8:00 am – 12:30 pm",
      website: "https://www.myfarmersmarkets.com/",
      phone: "+17607287343",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.52209, -117.153215],
    },
    type: "Feature",
    properties: {
      storeid: "63",
      name: "Temecula PROMENADE FARMER'S MARKET",
      address: "40460 Promenade Way, Temecula, CA 92591",
      dow: "Wednesdays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.myfarmersmarkets.com/",
      phone: "+17607287343",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.47920984675623, -117.09777197408141],
    },
    type: "Feature",
    properties: {
      storeid: "64",
      name: "VAIL HQ TEMECULA FARMER'S MARKET",
      address: "32115 Temecula Pkwy, Temecula, CA 92592",
      dow: "Tuesdays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.vailhq.com/all-events",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.56618260352156, -117.21165906266673],
    },
    type: "Feature",
    properties: {
      storeid: "65",
      name: "Murrieta Farmer’s Market",
      address: "24422 Village Walk Pl, Murrieta, CA 92562",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.myfarmersmarkets.com",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.07207137681447, -117.49230405797672],
    },
    type: "Feature",
    properties: {
      storeid: "66",
      name: "Fontana Farmers Market - Southridge Park",
      address: "14501 Live Oak Ave, Fontana, CA 92337",
      dow: "Thursdays",
      hours: "2:00 pm – 6:00 pm",
      website: "https://www.fontana.org/3496/Fontana-Farmers-Markets",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.180833561799155, -117.45906722991289],
    },
    type: "Feature",
    properties: {
      storeid: "67",
      name: "Fontana Farmers Market - Fontana Park",
      address: "15556 Summit Ave, Fontana, CA 92336",
      dow: "Sundays",
      hours: "12:00 pm – 5:00 pm",
      website: "https://www.fontana.org/3496/Fontana-Farmers-Markets",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.96783522639943, -117.02211414899934],
    },
    type: "Feature",
    properties: {
      storeid: "68",
      name: "Downtown Hemet Certified Farmer's and Artisanal Market",
      address: "135 E Florida Ave, Hemet, CA 92543",
      dow: "Saturdays",
      hours: "10:00 am – 4:00 pm",
      website: "",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.97844800508049, -117.54793886899016],
    },
    type: "Feature",
    properties: {
      storeid: "69",
      name: "The Valley Patch Farmers Market",
      address: "6413 Pats Ranch Rd, Jurupa Valley, CA 91752",
      dow: "Fridays",
      hours: "9:00 am – 12:00 pm",
      website: "",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
      closed: "yes",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.827803446564175, -116.52090177798223],
    },
    type: "Feature",
    properties: {
      storeid: "70",
      name: "Certified Farmers' Market Palm Springs Outdoor",
      address: "2300 E Baristo Rd, Palm Springs, CA 92262",
      dow: "Saturdays",
      hours: "8:30 am – 1:30 pm",
      season: "Oct to May",
      website: "https://www.certifiedfarmersmarkets.org/",
      phone: "+18447327628",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.824666154271824, -116.52433500550215],
    },
    type: "Feature",
    properties: {
      storeid: "71",
      name: "Certified Farmers' Market Palm Springs Indoor",
      address: "401 S Pavilion Way, Palm Springs, CA 92262",
      dow: "Saturdays",
      hours: "8:30 am – 1:30 pm",
      season: "June to Sept",
      website: "https://www.certifiedfarmersmarkets.org/",
      phone: "+18447327628",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.67939203654723, -116.30151008536755],
    },
    type: "Feature",
    properties: {
      storeid: "72",
      name: "Certified Farmers' Market La Quinta",
      address: "78100 Main St, La Quinta, CA 92253",
      dow: "Sundays",
      hours: "8:30 am – 1:30 pm",
      season: "Oct to April",
      website: "https://www.certifiedfarmersmarkets.org/",
      phone: "+18447327628",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.72416089596426, -116.40046198776284],
    },
    type: "Feature",
    properties: {
      storeid: "73",
      name: "Certified Farmers' Market Palm Desert",
      address: "72559 CA-111, Palm Desert, CA 92260",
      dow: "Wednesdays",
      hours: "8:30 am – 1:30 pm",
      season: "Oct to April",
      website: "https://www.certifiedfarmersmarkets.org/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.06486502482936, -117.75082011639213],
    },
    type: "Feature",
    properties: {
      storeid: "74",
      name: "Pomona Certified Farmers Market",
      address: "8336009037, Pomona, CA 91768",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://pomonacfa.org/",
      phone: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.115079733843274, -117.57231076899018],
    },
    type: "Feature",
    properties: {
      storeid: "75",
      name: "Terra Vista Farmers Market",
      address: "Aspen Ave, Rancho Cucamonga, CA 91730",
      dow: "Saturday",
      hours: "9:00 am – 2:00 pm",
      website: "https://heritagefarmersmarket.org/terra-vista",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.06499602431186, -117.18106257534463],
    },
    type: "Feature",
    properties: {
      storeid: "76",
      name: "Saturday Morning Certified Farmers Market",
      address: "100 E Redlands Blvd, Redlands, CA 92373",
      dow: "Saturdays",
      hours: "8:00 am – 11:00 am",
      website: "",
      phone: "+19097987629",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.06328955019743, -117.18209254360059],
    },
    type: "Feature",
    properties: {
      storeid: "77",
      name: "Thursday Night Market",
      address: "35 Cajon St #25, Redlands, CA 92373",
      dow: "Thursdays",
      hours: "6:00 pm – 9:30 pm",
      website: "http://www.cityofredlands.org/redlandsmarketnight",
      phone: "+19097987548",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.099038818004956, -117.37362674707914],
    },
    type: "Feature",
    properties: {
      storeid: "78",
      name: "Rialto Certified Farmers Market",
      address: "290 W Rialto Ave, Rialto, CA 92376",
      dow: "Wednesdays",
      hours: "10:00 am – 2:00 pm",
      website: "https://www.yourrialto.com/206/Rialto-Certified-Farmers-Market",
      phone: "+19098202519",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.98769222447397, -117.3723208623021],
    },
    type: "Feature",
    properties: {
      storeid: "79",
      name: "The Riverside Downtown Farmers Market",
      address: "Main St, Riverside, CA 92501",
      dow: "Saturdays",
      hours: "8:00 am – 1:00 pm",
      website: "https://riversideca.gov/arts2/farmers-market.asp",
      phone: "+17143453087",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.90914168370325, -117.4543156872107],
    },
    type: "Feature",
    properties: {
      storeid: "80",
      name: "Galleria at Tyler Riverside Farmers' Market",
      address: "3487 Tyler St, Riverside, CA 92503",
      dow: "Sundays",
      hours: "8:30 am – 2:00 pm",
      website:
        "https://www.coastalpacificmarkets.com/locations/galleria-tyler-riverside",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.23870657574409, -118.55604488720098],
    },
    type: "Feature",
    properties: {
      storeid: "81",
      name: "Northridge Farmers' Market & Family Festival",
      address: "9301 Tampa Ave, Northridge, CA 91324",
      dow: "Wednesdays",
      hours: "5:00 pm – 9:00 pm",
      website: "https://www.coastalpacificmarkets.com/locations/northridge",
      phone: "",
      season: "March 16th - November 5th, 2022",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.28555333114429, -118.71810568719967],
    },
    type: "Feature",
    properties: {
      storeid: "82",
      name: "Simi Valley City Hall Certified Farmers' Market",
      address: "2757 Tapo Canyon Rd. Simi Valley, CA 93063",
      dow: "Fridays",
      hours: "11:00 am – 3:30 pm",
      website: "https://www.coastalpacificmarkets.com/locations/simi-valley",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.20274258067693, -118.60119838720198],
    },
    type: "Feature",
    properties: {
      storeid: "83",
      name: "Canoga Park Certified Farmers' Market",
      address: "7248 Owensmouth Ave. Canoga Park, CA 91303",
      dow: "Saturdays",
      hours: "9:00 am – 1:30 pm",
      website: "https://www.coastalpacificmarkets.com/locations/canoga-park",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.20652530397755, -117.10476437185903],
    },
    type: "Feature",
    properties: {
      storeid: "84",
      name: "Running Springs Farmers Market & Artisan Faire",
      address: "2645 Whispering Pines Dr, Running Springs, CA 92382",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://runningspringschamber.com/",
      phone: "+19098672411",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.75985875315923, -116.95819850255786],
    },
    type: "Feature",
    properties: {
      storeid: "85",
      name: "San Jacinto Certified Farmers Market",
      address: "2575 S San Jacinto Ave, San Jacinto, CA 92582",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website:
        "https://www.facebook.com/SanJacintoValleyCertifiedFarmersMarket/",
      phone: "+19512389016",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.75985875315923, -116.95819850255786],
    },
    type: "Feature",
    properties: {
      storeid: "86",
      name: "San Jacinto Certified Farmers Market",
      address: "2575 S San Jacinto Ave, San Jacinto, CA 92582",
      dow: "Thursday",
      hours: "8:00 am – 1:00 pm",
      website:
        "https://www.facebook.com/SanJacintoValleyCertifiedFarmersMarket/",
      phone: "+19512389016",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.041359433918956, -117.04155346188391],
    },
    type: "Feature",
    properties: {
      storeid: "87",
      name: "Yucaipa Certified Farmers Market",
      address: "12062 California St, Yucaipa, CA 92399",
      dow: "Fridays",
      hours: "6:00 pm – 9:00 pm",
      website: "",
      phone: "+17604010028",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
      closed: "yes",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.016306324651936, -118.49874204522962],
    },
    type: "Feature",
    properties: {
      storeid: "88",
      name: "Farmers Market - Wednesday/Saturday Downtown Santa Monica",
      address: "155-199 Arizona Ave, Santa Monica, CA 90401",
      dow: "Wednesdays and Saturdays",
      hours: "8:00 am – 1:00 pm",
      website: "https://www.santamonica.gov/places/farmers-markets",
      phone: "+13104588712",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.0999683786424, -118.32817056254467],
    },
    type: "Feature",
    properties: {
      storeid: "89",
      name: "Hollywood Farmers’ Market",
      address: "1600 Ivar Ave, Los Angeles, CA 90028",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "https://seela.org/markets-hollywood/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.18300431502125, -118.30688023138126],
    },
    type: "Feature",
    properties: {
      storeid: "90",
      name: "Burbank Farmers Market",
      address: "101 N Glenoaks Blvd, Burbank, CA 91501",
      dow: "Saturdays",
      hours: "8:00 am – 12:00 pm",
      website: "http://www.burbankfarmersmarket.org/",
      phone: "+18188474774",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.18300431502125, -118.30688023138126],
    },
    type: "Feature",
    properties: {
      storeid: "91",
      name: "South Pasadena Farmer's Market",
      address: "920 Meridian Ave, South Pasadena, CA 91030",
      dow: "Thursdays",
      hours: "4:00 pm – 8:00 pm",
      website: "https://southpasadenafarmersmarket.org/",
      phone: "+16264032820",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.00426206985987, -118.43072197371502],
    },
    type: "Feature",
    properties: {
      storeid: "92",
      name: "Mar Vista Farmers' Market",
      address: "12198 Venice Blvd., Los Angeles, CA 90066",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.marvistafarmersmarket.org/",
      phone: "+13108614444",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.205669179269734, -118.22809677370913],
    },
    type: "Feature",
    properties: {
      storeid: "93",
      name: "Montrose Farmers Market",
      address: "2338-2312 Honolulu Ave, Montrose, CA 91020",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://shopmontrose.com/harvest-market-and-marketplace/",
      phone: "+18186462880",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.942291200442526, -118.18350210255264],
    },
    type: "Feature",
    properties: {
      storeid: "94",
      name: "South Gate Farmers' Market",
      address: "4951-4981 Tweedy Blvd, South Gate, CA 90280",
      dow: "Mondays",
      hours: "8:30 am – 1:30 pm",
      website: "https://www.goodveg.org/monday-south-gate",
      phone: "+15626662612",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.88461061428794, -118.1238469737186],
    },
    type: "Feature",
    properties: {
      storeid: "95",
      name: "Bellflower Farmers' Market",
      address: "16521 Adenmoor Ave, Bellflower, CA 90706",
      dow: "Mondays",
      hours: "3:00 pm – 7:00 pm",
      website:
        "https://www.bellflower.org/community/community/farmers_market.php",
      phone: "+15628041424",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.0943037599534, -118.35130908697171],
    },
    type: "Feature",
    properties: {
      storeid: "96",
      name: "Helen Albert Certified Farmers' Market",
      address: "1200 N Vista St, West Hollywood, CA 90046",
      dow: "Mondays",
      hours: "9:00 am – 2:00 pm",
      website:
        "https://www.weho.org/community/recreation-services/helen-albert-certified-farmers-market",
      phone: "+13238486530",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.86778818321047, -118.18471097407007],
    },
    type: "Feature",
    properties: {
      storeid: "97",
      name: "Houghton Park Farmers' Market",
      address: "6151-6191 Atlantic Ave, Long Beach, CA 90805",
      dow: "Mondays",
      hours: "Winter 2 pm - 6 pm, Summer 3pm - 7pm",
      website:
        "https://www.weho.org/community/recreation-services/helen-albert-certified-farmers-market",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.10172906340256, -118.3085179028991],
    },
    type: "Feature",
    properties: {
      storeid: "98",
      name: "East Hollywood Certified Farmers' Market",
      address: "5448 Hollywood Blvd, Los Angeles, CA 90027",
      dow: "Mondays",
      hours: "3:30 pm - 7:30 pm",
      website: "https://www.ehfarmersmkt.com/our-services/",
      phone: "+13232405852",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.82679828582443, -118.32709877592328],
    },
    type: "Feature",
    properties: {
      storeid: "99",
      name: "Torrance Certified Farmers' Market",
      address: "2200 Crenshaw Blvd, Torrance, CA 90501",
      dow: "Tuesdays",
      hours: "8:00 am – 1:00 pm",
      website:
        "https://www.torranceca.gov/our-city/cultural-services/farmers-market",
      phone: "+13107817520",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.154901061390284, -118.14282696056948],
    },
    type: "Feature",
    properties: {
      storeid: "100",
      name: "Pasadena Certified Farmers' Market at Villa Park",
      address: "363 E Villa St, Pasadena, CA 91101",
      dow: "Tuesdays",
      hours: "8:30 am – 12:30 pm",
      website: "https://www.pasadenafarmersmarket.org/",
      phone: "+16264490179",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.886791500318466, -118.40969091824947],
    },
    type: "Feature",
    properties: {
      storeid: "101",
      name: "Manhattan Beach Certified Farmers’ Market",
      address: "326 13th St, Manhattan Beach, CA 90266",
      dow: "Tuesdays",
      hours: "11:00 am – 3:00 pm",
      website:
        "https://www.downtownmanhattanbeach.com/manhattan-beach-farmers-market/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.02524892007214, -118.39458890290132],
    },
    type: "Feature",
    properties: {
      storeid: "102",
      name: "Culver City Farmers Market",
      address: "Main St, Culver City, CA 90232",
      dow: "Tuesdays",
      hours: "2:00 pm – 7:00 pm",
      website: "https://www.culvercity.org/Explore/Farmers-Market",
      phone: "",
      season: "+14247726628",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.09089867806785, -118.27734613173543],
    },
    type: "Feature",
    properties: {
      storeid: "103",
      name: "Silver Lake Farmers Market",
      address: "3700 Sunset Blvd, Los Angeles, CA 90026",
      dow: "Tuesdays",
      hours: "2:00 pm – 7:00 pm",
      website:
        "https://www.yelp.com/biz/silverlake-farmers-market-los-angeles-5",
      phone: "",
      season: "+12134844002",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.11103740604088, -118.19242278940678],
    },
    type: "Feature",
    properties: {
      storeid: "104",
      name: "Old LA Farmers' Market",
      address: "Avenue 58, Marmion Way, Los Angeles, CA 90042",
      dow: "Tuesdays",
      hours: "3:00 pm – 8:00 pm",
      website: "http://www.oldla.org/",
      phone: "",
      season: "+13234494100",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.76601757589582, -118.16666550290903],
    },
    type: "Feature",
    properties: {
      storeid: "105",
      name: "Bixby Park Local Harvest Farmers Market",
      address: "130 Cherry Ave, Long Beach, CA 90803",
      dow: "Tuesdays",
      hours: "3:00 pm – 7:00 pm",
      website: "https://www.yelp.com/biz/harvest-farmers-market-long-beach",
      phone: "",
      season: "+15624499299",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.1569474217415, -118.4370554010455],
    },
    type: "Feature",
    properties: {
      storeid: "106",
      name: "Sherman Oaks Farmers Market",
      address: "14006 Riverside Dr, Sherman Oaks, CA 91423",
      dow: "Tuesdays",
      hours: "2:00 pm – 6:00 pm",
      website: "https://www.westfield.com/fashionsquare/events",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.91625165157985, -118.07142260290463],
    },
    type: "Feature",
    properties: {
      storeid: "107",
      name: "Norwalk Farmers Market",
      address: "12700 Norwalk Blvd, Norwalk, CA 90650",
      dow: "Tuesdays",
      hours: "9:00 am – 1:00 pm",
      website: "https://venaver.org/norwalk-farmers-market",
      phone: "+18007792115",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.200576346065745, -118.15784781637727],
    },
    type: "Feature",
    properties: {
      storeid: "108",
      name: "Altadena Farmers' Market",
      address: "587 W Palm St, Altadena, CA 91001",
      dow: "Wednesdays",
      hours: "4:00 pm – 7:00 pm",
      website: "http://altadenafarmersmarket.com/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.97264497235294, -118.2056908771319],
    },
    type: "Feature",
    properties: {
      storeid: "109",
      name: "Huntington Park Farmers' Market",
      address: "6938-7198 Bissell St, Huntington Park, CA 90255",
      dow: "Wednesdays",
      hours: "8:30 am – 1:30 pm",
      website: "https://www.goodveg.org/wednesday-huntington-park-1",
      phone: "+15623445482",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.073904152448655, -118.21311173172221],
    },
    type: "Feature",
    properties: {
      storeid: "110",
      name: "Lincoln Heights Certified Farmers Market",
      address: "2800 N Broadway, Los Angeles, CA 90031",
      dow: "Wednesdays",
      hours: "3:00 pm – 7:00 pm",
      website: "https://www.instagram.com/lhcfm/",
      phone: "+13234370203",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.033144241627895, -118.29272148754666],
    },
    type: "Feature",
    properties: {
      storeid: "111",
      name: "Adams/Vermont Farmer's Market",
      address: "1432 W Adams Blvd, Los Angeles, CA 90007",
      dow: "Wednesdays",
      hours: "2:00 pm – 6:00 pm",
      website: "https://www.facebook.com/adamsvermontfm/",
      phone: "+13106210336",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.0615955083786, -118.41681717589938],
    },
    type: "Feature",
    properties: {
      storeid: "112",
      name: "Century City Farmer's Market",
      address: "10100 CA-2, Los Angeles, CA 90067",
      dow: "Thursdays",
      hours: "10:00 am – 2:00 pm",
      website: "https://www.facebook.com/CenturyCityFM/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.92015666090286, -118.41602857590333],
    },
    type: "Feature",
    properties: {
      storeid: "113",
      name: "El Segundo Certified Farmers' Market",
      address: "314-348 Main St, El Segundo, CA 90245",
      dow: "Thursdays",
      hours: "3:00 pm – 7:00 pm",
      website:
        "https://www.elsegundorecparks.org/programs-services/el-segundo-certified-farmers-market",
      phone: "+13105242700",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.0437113316149, -118.37947270473526],
    },
    type: "Feature",
    properties: {
      storeid: "114",
      name: "La Cienega Farmers' Market",
      address:
        "1835 South La Cienega Boulevard Corner of 18th St. &, S Holt Ave, Los Angeles, CA 90035",
      dow: "Thursdays",
      hours: "2:00 pm – 7:00 pm",
      website: "http://www.lacienegafarmersmkt.com/",
      phone: "+14242872280",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.062006642996415, -118.44624677589928],
    },
    type: "Feature",
    properties: {
      storeid: "115",
      name: "Westwood Village Farmers Market",
      address: "1036 Broxton Ave, Los Angeles, CA 90024",
      dow: "Thursdays",
      hours: "12:00 pm – 5:00 pm",
      website: "http://www.farmermark.com/westwood",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.101720179459946, -118.30852863172156],
    },
    type: "Feature",
    properties: {
      storeid: "116",
      name: "East Hollywood Certified Farmers' Market",
      address: "5448 Hollywood Blvd, Los Angeles, CA 90027",
      dow: "Thursdays",
      hours: "3:30 pm – 7:30 pm",
      website: "https://www.ehfarmersmkt.com/",
      phone: "+13232405852",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.84238980241205, -118.18476431823485],
    },
    type: "Feature",
    properties: {
      storeid: "117",
      name: "Uptown Certified Farmers Market",
      address: "Atlantic Avenue &, E 46th St, Long Beach, CA 90807",
      dow: "Thursdays",
      hours: "3:00 pm – 7:00 pm",
      website: "https://www.goodveg.org/",
      phone: "+15626662534",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.00550964358524, -118.2567538875473],
    },
    type: "Feature",
    properties: {
      storeid: "118",
      name: "Central Avenue Farmers' Market",
      address: "4301 S Central Ave, Los Angeles, CA 90011",
      dow: "Thursdays",
      hours: "10:00 am – 3:00 pm",
      website: "http://www.seela.org/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.83829291844147, -118.38973784290313],
    },
    type: "Feature",
    properties: {
      storeid: "119",
      name: "Redondo Beach Certified Farmers Market",
      address: "309 Esplanade, Redondo Beach, CA 90277",
      dow: "Thursdays",
      hours: "8:00 am – 1:00 pm",
      website:
        "http://www.redondo.org/depts/recreation/cultural_arts/farmers_market.asp",
      phone: "+13103721171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.73909778673814, -118.28548940417038],
    },
    type: "Feature",
    properties: {
      storeid: "120",
      name: "San Pedro Farmers Market",
      address: "396 W 6th St, San Pedro, CA 90731",
      dow: "Fridays",
      hours: "11:00 am – 3:00 pm",
      website: "https://venaver.org/san-pedro-farmers-market/",
      phone: "+18007792115",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.077266657096104, -118.25850401638075],
    },
    type: "Feature",
    properties: {
      storeid: "121",
      name: "Echo Park Farmers' Market",
      address: "1146 Logan St, Los Angeles, CA 90026",
      dow: "Fridays",
      hours: "3:00 pm – 7:30 pm",
      website: "http://seela.org/markets-echo-park/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.146126842131, -118.00091694521417],
    },
    type: "Feature",
    properties: {
      storeid: "122",
      name: "Monrovia Farmers Market",
      address: "S Myrtle Ave, Monrovia, CA 91016",
      dow: "Fridays",
      hours: "5:00 pm – 9:00 pm",
      website: "http://monroviastreetfair.com/new/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.77102184381482, -118.19075064707222],
    },
    type: "Feature",
    properties: {
      storeid: "123",
      name: "Long Beach Downtown Farmers Market",
      address: "315-412 The Promenade N, Long Beach, CA 90802",
      dow: "Fridays",
      hours: "10:00 am – 2:00 pm",
      website: "https://facebook.com/DTLBfarmersmarket/",
      phone: "+15627263254",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.86252427839597, -118.39501488755144],
    },
    type: "Feature",
    properties: {
      storeid: "124",
      name: "Hermosa Beach Friday Farmers Market",
      address: "1035 Valley Dr, Hermosa Beach, CA 90254",
      dow: "Fridays",
      hours: "12:00 pm – 4:00 pm",
      website: "http://hermosabeachfarmersmarket.org/",
      phone: "+13108965110",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.070911373099705, -117.92172074521633],
    },
    type: "Feature",
    properties: {
      storeid: "125",
      name: "West Covina Farmers Market",
      address: "195 S Glendora Ave, West Covina, CA 91790",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://www.covinafarmersmarket.com/",
      phone: "+19098187913",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.97896301068193, -118.0364686740542],
    },
    type: "Feature",
    properties: {
      storeid: "126",
      name: "Uptown Whittier Farmer’s Market",
      address: "13018 Philadelphia St, Whittier, CA 90601",
      dow: "Fridays",
      hours: "8:00 am – 1:00 pm",
      website: "https://whittieruptown.org/certified-farmers-market/",
      phone: "+15626962662",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    },
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.0209756896197, -118.46779683172372
      ]
    },
    type: "Feature",
    properties: {
      storeid: "127",
      name: "Santa Monica Saturday Pico Farmers Market",
      address: "2200 Virginia Ave, Santa Monica, CA 90405",
      dow: "Saturdays",
      hours: "8:00 am – 1:00 pm",
      website: "https://santamonica.gov/categories/programs/farmers-market",
      phone: "+13104588712",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.03500721770039, -118.16007846055871
      ]
    },
    type: "Feature",
    properties: {
      storeid: "128",
      name: "East Los Angeles Farmers Market",
      address: "4801 E 3rd St, East Los Angeles, CA 90022",
      dow: "Saturdays",
      hours: "8:00 am – 1:00 pm",
      website: "https://eastmontcommunitycenter.org/farmers-market/",
      phone: "+13237267998",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.090845367578204, -118.27733540288641
      ]
    },
    type: "Feature",
    properties: {
      storeid: "129",
      name: "Silver Lake Farmers Market",
      address: "3700 Sunset Blvd, Los Angeles, CA 90026",
      dow: "Saturdays",
      hours: "8:00 am – 1:30 pm",
      website: "https://eastmontcommunitycenter.org/farmers-market/",
      phone: "+12134844002",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.090845367578204, -118.27733540288641
      ]
    },
    type: "Feature",
    properties: {
      storeid: "130",
      name: "Silver Lake Farmers Market",
      address: "3700 Sunset Blvd, Los Angeles, CA 90026",
      dow: "Tuesdays",
      hours: "1:30 pm – 7:00 pm",
      website: "https://eastmontcommunitycenter.org/farmers-market/",
      phone: "+12134844002",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.16099126828341, -118.09359114521371
      ]
    },
    type: "Feature",
    properties: {
      storeid: "131",
      name: "Pasadena Victory Park Farmer's Market",
      address: "2925 E Sierra Madre Blvd, Pasadena, CA 91107",
      dow: "Saturdays",
      hours: "1:30 pm – 7:00 pm",
      website: "https://www.pasadenafarmersmarket.org/",
      phone: "+16264490179",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.011222023278116, -118.3364845317241
      ]
    },
    type: "Feature",
    properties: {
      storeid: "132",
      name: "Crenshaw Farmers' Market",
      address: "3650 W Martin Luther King Jr Blvd, Los Angeles, CA 90008",
      dow: "Saturdays",
      hours: "10:00 am – 3:00 pm",
      website: "http://www.seela.org/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        33.97945889022979, -118.45871114521881
      ]
    },
    type: "Feature",
    properties: {
      storeid: "133",
      name: "Marina Del Rey Farmer's Market",
      address: "Vía Marina &, Panay Way, Marina Del Rey, CA 90292",
      dow: "Saturdays",
      hours: "9:00 am – 2:00 pm",
      website: "https://www.facebook.com/MDRFarmersMarket/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        33.97606312309258, -118.41651001638351
      ]
    },
    type: "Feature",
    properties: {
      storeid: "134",
      name: "Playa Vista Farmers' Market",
      address: "12775 Millennium Dr, Playa Vista, CA 90094",
      dow: "Saturdays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.farmermark.com/playavista/",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        34.2072621307812, -118.20629505609234
      ]
    },
    type: "Feature",
    properties: {
      storeid: "135",
      name: "La Cañada Flintridge Farmer's Market",
      address: "1300 Foothill Blvd, La Cañada Flintridge, CA 91011",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://www.rawinspiration.org/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        33.84674096785665, -117.99336748755178
      ]
    },
    type: "Feature",
    properties: {
      storeid: "136",
      name: "Buena Park Certified Farmers' Market",
      address: "8000-8092 La Palma Ave, Buena Park, CA 90620",
      dow: "Saturdays",
      hours: "9:00 am – 2:00 pm",
      website: "https://bestfarmersmarkets.org/place/buena-park-certified-farmers-market-buena-park-ca.html",
      phone: "+15624499299",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [
        33.94209279691757, -118.13419643172595
      ]
    },
    type: "Feature",
    properties: {
      storeid: "137",
      name: "Downey Farmers Market",
      address: "11102 La Reina Ave, Downey, CA 90241",
      dow: "Saturdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://www.rawinspiration.org/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.09089867806785, -118.27734613173543],
    },
    type: "Feature",
    properties: {
      storeid: "138",
      name: "Silver Lake Farmers Market",
      address: "3700 Sunset Blvd, Los Angeles, CA 90026",
      dow: "Saturdays",
      hours: "8:00 am – 1:30 pm",
      website:
        "https://www.yelp.com/biz/silverlake-farmers-market-los-angeles-5",
      phone: "+12134844002",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.86846266476214, -118.06156438811261],
    },
    type: "Feature",
    properties: {
      storeid: "139",
      name: "Cerritos Farmers' Market",
      address: "Park Plaza Dr, Cerritos, CA 90703",
      dow: "Saturdays",
      hours: "8:00 am – 12:00 pm",
      website: "https://www.goodveg.org/",
      phone: "+15629831665",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.157668157229914, -118.63859304706128],
    },
    type: "Feature",
    properties: {
      storeid: "140",
      name: "Calabasas Farmers Market",
      address: "23504 Calabasas Rd, Calabasas, CA 91302",
      dow: "Saturdays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.rawinspiration.org/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.044920408297656, -117.84672694521699],
    },
    type: "Feature",
    properties: {
      storeid: "141",
      name: "Farmers Market at Mt. SAC",
      address: "1100 N Grand Ave Lot B, Walnut, CA 91789",
      dow: "Saturdays",
      hours: "8:00 am – 2:00 pm",
      website: "http://www.regionalchambersgv.com/events/details/the-farmers-market-at-mt-sac-sponsored-by-the-regional-chamber-10-21-2017-8026",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.02913393373956, -118.41129333479033],
    },
    type: "Feature",
    properties: {
      storeid: "142",
      name: "Motor Avenue Farmers' Market",
      address: "Motor Avenue & National Boulevard, Los Angeles, CA 90034",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.motoravenuemarket.com/",
      phone: "+13102029002",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.08384535468643, -118.37491177589875],
    },
    type: "Feature",
    properties: {
      storeid: "143",
      name: "Melrose Place Farmers Market",
      address: "8400 Melrose Ave, Los Angeles, CA 90069",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.motoravenuemarket.com/",
      phone: "+12132148944",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.075240435689146, -118.32400261638074],
    },
    type: "Feature",
    properties: {
      storeid: "144",
      name: "Larchmont Farmers' Market",
      address: "209 N Larchmont Blvd, Los Angeles, CA 90004",
      dow: "Sundays",
      hours: "10:00 am – 2:00 pm",
      website: "https://larchmontla.com/event/larchmont-village-farmers-market/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.144551887501386, -118.39487410473244],
    },
    type: "Feature",
    properties: {
      storeid: "145",
      name: "Studio City Farmers Market",
      address: "2052 Ventura Pl, Studio City, CA 91604",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://studiocityfarmersmarket.com/",
      phone: "+18186557744",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.75679182799952, -118.11261845019088],
    },
    type: "Feature",
    properties: {
      storeid: "146",
      name: "Long Beach Southeast Farmers Market",
      address: "6602 E Marina Dr, Long Beach, CA 90803",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.goodveg.org/",
      phone: "+15629831665",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.04796377245104, -118.2499088163927],
    },
    type: "Feature",
    properties: {
      storeid: "147",
      name: "Historic Downtown Farmers Market",
      address: "5th Street @, S Spring St, Los Angeles, CA 90013",
      dow: "Sundays",
      hours: "9:00 am – 3:00 pm",
      website: "http://www.sfma.net/",
      phone: "+18058456100",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.002098200421536, -118.48318884574141],
    },
    type: "Feature",
    properties: {
      storeid: "148",
      name: "Santa Monica Sunday Main Street Farmers Markets",
      address: "2640 Main St, Santa Monica, CA 90404",
      dow: "Sundays",
      hours: "8:30 am – 1:30 pm",
      website: "https://santamonica.gov/places/farmers-markets/main-street-farmers-market",
      phone: "+13104588712",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.11776044876755, -118.26039246057061],
    },
    type: "Feature",
    properties: {
      storeid: "149",
      name: "Atwater Village Farmers’ Market",
      address: "23528 Larga Ave, Los Angeles, CA 90039",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.seela.org/markets-atwater-village",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.11776044876755, -118.26039246057061],
    },
    type: "Feature",
    properties: {
      storeid: "149",
      name: "Atwater Village Farmers’ Market",
      address: "23528 Larga Ave, Los Angeles, CA 90039",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.seela.org/markets-atwater-village",
      phone: "+13234633171",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.073892802203716, -118.39911162884819],
    },
    type: "Feature",
    properties: {
      storeid: "150",
      name: "Beverly Hills Farmers Market",
      address: "9300 Civic Center Dr, Beverly Hills, CA 90210",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.beverlyhills.org/departments/communityservices/farmersmarket",
      phone: "+13102856830",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.05084803430301, -118.47508455872062],
    },
    type: "Feature",
    properties: {
      storeid: "151",
      name: "Brentwood Farmers Market",
      address: "741 S Gretna Green Way, Los Angeles, CA 90049",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "https://ccfm.com/brentwood/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.04796523085984, -118.52544140290071],
    },
    type: "Feature",
    properties: {
      storeid: "152",
      name: "Pacific Palisades Farmers Market",
      address: "N Swarthmore Ave, Pacific Palisades, CA 90272",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.rawinspiration.org/",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.037797184436, -118.68962401639301],
    },
    type: "Feature",
    properties: {
      storeid: "153",
      name: "Malibu Farmers Market",
      address: "23555 Civic Center Way, Malibu, CA 90265",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://malibufarmersmarket.net/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.18605668315413, -118.51308163358463],
    },
    type: "Feature",
    properties: {
      storeid: "154",
      name: "Encino Farmers Market",
      address: "17400 Victory Blvd, Van Nuys, CA 91406",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.encinofarmersmarket.org/",
      phone: "+18187086611",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.20565143324372, -118.22804312987998],
    },
    type: "Feature",
    properties: {
      storeid: "155",
      name: "Montrose Farmers Market",
      address: "2338-2312 Honolulu Ave, Montrose, CA 91020",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://shopmontrose.com/harvest-market-and-marketplace/",
      phone: "+18186462880",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.03976458010486, -118.33504142988491],
    },
    type: "Feature",
    properties: {
      storeid: "155",
      name: "Wellington Square Farmers Market",
      address: "4394 W Washington Blvd, Los Angeles, CA 90016",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.wellingtonsquarefarmersmarket.com/",
      phone: "+13102599086",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.03976458010486, -118.33504142988491],
    },
    type: "Feature",
    properties: {
      storeid: "156",
      name: "Wellington Square Farmers Market",
      address: "4394 W Washington Blvd, Los Angeles, CA 90016",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "https://www.wellingtonsquarefarmersmarket.com/",
      phone: "+13102599086",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.151750133216986, -118.82371186056963],
    },
    type: "Feature",
    properties: {
      storeid: "157",
      name: "Westlake Village Farmers Market",
      address: "2797 Agoura Rd, Westlake Village, CA 91361",
      dow: "Sundays",
      hours: "7:00 am – 2:00 pm",
      website: "",
      phone: "+18185918161",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.09382688497258, -118.12842576057133],
    },
    type: "Feature",
    properties: {
      storeid: "158",
      name: "Alhambra Farmers Market",
      address: "100 S 2nd St, Alhambra, CA 91801",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "https://www.cityofalhambra.org/376/Certified-Farmers-Market",
      phone: "+16265705081",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.10785962998829, -118.05803954707892],
    },
    type: "Feature",
    properties: {
      storeid: "159",
      name: "Temple City Certified Farmers' Market",
      address: "9701 Las Tunas Dr, Temple City, CA 91780",
      dow: "Sundays",
      hours: "7:30 am – 12:30 pm",
      website: "http://www.ci.temple-city.ca.us/287/Farmers-Market",
      phone: "+16267107069",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.09586211025547, -117.71645848940727],
    },
    type: "Feature",
    properties: {
      storeid: "160",
      name: "Claremont Farmers & Artisans Market",
      address: "Harvard Ave N, Claremont, CA 91711",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.claremontforum.org/",
      phone: "+17143453087",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.778907470147544, -118.37400074708863],
    },
    type: "Feature",
    properties: {
      storeid: "161",
      name: "Palos Verdes Farmers Market",
      address: "27118 Silver Spur Rd, Rolling Hills Estates, CA 90274",
      dow: "Sundays",
      hours: "8:00 am – 1:00 pm",
      website: "http://www.facebook.com/palosverdesfarmersmarket",
      phone: "+13103288076",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.04583344639638, -118.45061132988478],
    },
    type: "Feature",
    properties: {
      storeid: "162",
      name: "West LA Farmers Market",
      address: "1635 Purdue Ave, Los Angeles, CA 90025",
      dow: "Sundays",
      hours: "9:00 am – 2:00 pm",
      website: "http://www.wlafarmersmarket.com/",
      phone: "+13107395028",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.15276498119855, -118.35357225871756],
    },
    type: "Feature",
    properties: {
      storeid: "163",
      name: "Toluca Lake Farmers Market",
      address: "10225 Riverside Dr, Toluca Lake, CA 91602",
      dow: "Sundays",
      hours: "9:30 am – 2:30 pm",
      website: "http://www.tolucalakefarmersmarket.org/",
      phone: "+18183326214",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [33.958727258318625, -118.39359641824736],
    },
    type: "Feature",
    properties: {
      storeid: "164",
      name: "Sundays Westchester Farmers’ Market",
      address: "6200 W 87th St, Los Angeles, CA 90045",
      dow: "Sundays",
      hours: "9:00 am – 1:00 pm",
      website: "http://www.westchesterfarmersmkt.com/",
      phone: "+14242912416",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.4093380076333, -118.56880702311908],
    },
    type: "Feature",
    properties: {
      storeid: "165",
      name: "Santa Clarita Certified Farmers' Market",
      address: "26455 Rockwell Canyon Rd, Santa Clarita, CA 91355",
      dow: "Sundays",
      hours: "8:00 am –12:00 pm",
      website: "https://www.vccfm.org/santa-clarita",
      phone: "+18055296266",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
},
  {
    geometry: {
      type: "Point",
      coordinates: [32.97752147851624, -115.53470781997662],
    },
    type: "Feature",
    properties: {
      storeid: "166",
      name: "Brawley Farmers Market",
      address: "204 S Imperial Ave, Brawley, CA 92227",
      dow: "Saturdays",
      hours: "5:00 pm –9:00 pm",
      website: "http://www.brawley-ca.gov/section/farmers-markets",
      phone: "+17603443160",
      season: "January through April",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.21354618213798, -118.87525094051196],
    },
    type: "Feature",
    properties: {
      storeid: "167",
      name: "Ventura County Certified Farmers' Markets",
      address: "W Wilbur Rd &, W Thousand Oaks Blvd, Thousand Oaks, CA 91320",
      dow: "Thursdays",
      hours: "12:00 pm –5:00 pm",
      website: "https://www.vccfm.org/thousand-oaks",
      phone: "+18055296266",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.30525423184714, -119.2945072467542],
    },
    type: "Feature",
    properties: {
      storeid: "168",
      name: "Ventura Farmers' Market (Downtown)",
      address: "E Main St, Ventura, CA 93001",
      dow: "Saturdays",
      hours: "8:30 am – 12:00 pm",
      website: "https://www.vccfm.org/",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.33474351319665, -119.24918864619917],
    },
    type: "Feature",
    properties: {
      storeid: "169",
      name: "Midtown Ventura Certified Farmers' Market",
      address: "Parking Lot, Pacific View Mall, Ventura, CA 93003",
      dow: "Wednesdays",
      hours: "9:00 am – 1:00 pm",
      website: "http://vccfm.org/",
      phone: "+18055296266",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.24397416729687, -119.03083538897944],
    },
    type: "Feature",
    properties: {
      storeid: "170",
      name: "Camarillo Farmers Market",
      address: "2220 Ventura Blvd, Camarillo, CA 93010",
      dow: "Satursdays",
      hours: "8:00 am – 12:00 pm",
      website: "http://camarillofarmersmarket.com/",
      phone: "+18059873347",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.31546323252422, -119.15305828138546],
    },
    type: "Feature",
    properties: {
      storeid: "171",
      name: "Saticoy Certified Farmers Market",
      address: "11321 Violeta St, Ventura, CA 93004",
      dow: "Sundays",
      hours: "10:00 am – 2:00 pm",
      website: "http://saticoyfarmersmarket.org/",
      phone: "+18052939417",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  },
  {
    geometry: {
      type: "Point",
      coordinates: [34.38915803346127, -119.06104778934947],
    },
    type: "Feature",
    properties: {
      storeid: "172",
      name: "Santa Paula Certified Farmers Market",
      address: "963 E Santa Barbara St, Santa Paula, CA 93060",
      dow: "Fridays",
      hours: "3:00 pm – 7:00 pm",
      website: "",
      phone: "",
      season: "",
      category: "market3",
      description: "",
      email: "",
      image: "",
    }
  }
];

export default Markets;
